import React, { Suspense } from 'react';

import { Box, GDSBoxProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import {
  ErrorState,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { AppHeaderNavBar } from './header-nav/app-header-nav-bar.component';
import { AppFooter } from './app-footer/app-footer.component';

type BasePageProps = GDSBoxProps<'div'> & {
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
};

export const HEADER_NAV_BAR_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();

  const mainContentMinHeight = isPrivatePage
    ? `calc(100vh - ${HEADER_NAV_BAR_DEFAULT_HEIGHT}px)`
    : '100vh';

  const { formatMessage } = useIntl();

  return (
    <>
      {isPrivatePage && <AppHeaderNavBar />}
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          flex: '1',
          minHeight: mainContentMinHeight,
          overflowX: 'hidden',
          ...css,
        }}
        {...boxProps}
      >
        <Suspense fallback={<LoadingIndicator />}>
          <ObservabilityErrorBoundary
            fallback={
              <ErrorState
                headingString={formatMessage({
                  id: 'STR_ERROR_STATE_HEADING_GENERIC',
                })}
                bodyString={formatMessage({
                  id: 'STR_ERROR_STATE_BODY_API',
                })}
                buttonText={formatMessage({
                  id: 'STR_RELOAD',
                })}
              />
            }
            errorContext={{
              context: {
                Context: { location: 'Base Page ErrorBoundary' },
              },
            }}
          >
            {children}
          </ObservabilityErrorBoundary>
        </Suspense>
      </Box>
      {isPrivatePage && <AppFooter />}
    </>
  );
};
