import type { DropdownSection } from '@leagueplatform/web-common-components';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { useIntl } from '@leagueplatform/locales';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> & {
  text: string;
})[];

const QUERY_CACHE_AND_STALE_TIME = 15 * 1000 * 60;
export const useUserDropdownComponent = () => {
  const { formatMessage } = useIntl();
  const { data } = useGetUserProfile({
    staleTime: QUERY_CACHE_AND_STALE_TIME,
    cacheTime: QUERY_CACHE_AND_STALE_TIME,
  });
  const preferredFirstName =
    data?.userProfile?.preferredFirstName ||
    data?.userProfile?.firstName ||
    data?.email ||
    '';

  const userDropdownConfig: AppDropdownSection[] = [
    [
      {
        to: '/sign-out',
        text: formatMessage({ id: 'STR_LOG_OUT' }),
        linkProps: {
          color: 'onSurface.text.critical',
        },
      },
    ],
  ];
  const mobileNavDrawerLinksConfig = [
    {
      items: [
        {
          to: APP_MANAGED_ROUTES.SIGN_OUT,
          text: formatMessage({ id: 'STR_LOG_OUT' }),
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];
  const dropdownMenuItemProps = {
    preferredFirstName,
    menuItemsConfig: userDropdownConfig,
    mobileNavDrawerLinksConfig,
  };

  return { data: dropdownMenuItemProps };
};
